<template>
  <div class="full-height">
    <a-card title="编辑产品流程" class="full-height">
      <a-row gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px;">
          <a-input v-model="searchForm.search" placeholder="编号, 名称, 备注" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px;">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right;">
          <a-button type="primary" icon="plus" style="margin: 0 8px;"
            @click="addProcess(form)">新增流程</a-button>
        </div>
      </a-row>
      
      <a-row style="margin-bottom: 12px;">
        <a-table :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-popconfirm title="确定删除吗" @confirm="destroy(item.id)">
                <a-button type="danger" icon="delete" size="small">删除</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <!-- 这个是编辑流程 -->
    <form-modal v-model="visible" :form="targetItem" :processItems="processItems" @create="create" @update="update" />
  </div>
</template>

<style scoped>
.full-height {
  min-height: 100vh;
  /* 确保组件至少有视口的完整高度 */
}
</style>

<script>
import { ProductProcessList, ProductProcessDestroy } from '@/api/production'
import { ProductionTaskInfo, ProcessList, TaskDone } from '@/api/production'

export default {
  name: 'Process',
  components: {
    FormModal: () => import('./FormModal.vue'),
  },
  data() {
    return {
      columns: [
        {
          title: '步骤',
          dataIndex: 'index',
          key: 'index',
          customRender: (value, item, index) => {
            return `第${index + 1}步`
          },
        },
        // {
        //   title: '步骤',
        //   dataIndex: 'id',
        //   sorter: true,
        // },
        {
          title: '流程名称',
          dataIndex: 'process_name',
          sorter: true,
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: '156px'
        },
      ],
      searchForm: { search: '', page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      processItems: [],  //流程列表
      processItemsFix: [], //固定的流程，第一次请求保持不变
      targetItem: {},
      goods: '',
      add_button: false,
      form: {},
      form_visible: false,
      work_visible: false,
    };
  },
  computed: {
  },
  methods: {

    getProcessInfo() {
      ProcessList().then(data => {
        this.processItemsFix = data.results
        this.processItems = data.results
      })
    },

    getProductProcessList(goods) {
      if(goods===null){
        this.$message.error("此任务未添加生产产品,无法进行操作");
        this.loading = false;
        this.add_button = true
        return ''
      }
      ProductProcessList({ goods: goods }).then(data => {
        // 根据任务中产品的ID获取这个成品的生产流程
        console.log('data',data)
        this.pagination.total = data.count;
        this.items = data.results
        this.items = this.items.map(item => ({
          ...item,
          process_name: item.process_detail.name,
        }),
        )
        this.items.sort((a, b) => a.id - b.id);
      }).finally(() => {
        this.loading = false;
        this.fitterPress()
      });
    },

    fitterPress() {
      this.processItems = this.processItemsFix.filter(p =>
        !this.items.some(up => up.process === p.id)
      )
      if (this.processItems.length == 0) {
        this.add_button = true
      } else {
        this.add_button = false
      }
    },

    initialize() {
      this.goods = this.$route.query.goods
      this.getProcessInfo()
      this.getProductProcessList(this.goods)
    },
    list() {
      // this.loading = false;
      this.loading = true;
      this.getProductProcessList(this.goods)
    },
    create(item) {
      // this.items.splice(0, 0, item);
      this.list();
    },
    update(item) {
      this.items.splice(this.items.findIndex(i => i.id == item.id), 1, item);
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    addProcess(item) {
      this.visible = true;
      if (item.process) {

        this.targetItem = {
          "goods": item.goods,
          "process": item.process,
          "name": item.process_name,
          "id": id
        };
      } else {
        this.targetItem = { "goods": this.goods };
      }

    },
    destroy(id) {
      ProductProcessDestroy({ id }).then(() => {
        // this.items.splice(this.items.findIndex(item => item.id == id), 1);
        this.$message.success('删除成功');
        this.list();
      });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
      this.list();
    },
  },
  mounted() {
    this.initialize();
  },
}
</script>